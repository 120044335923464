<template>
  <div class="FormProject">
    <FormSect title="Projektas">
      <Row>
        <Column :md="6">
          <Field
            label="Pavadinimas"
            required
            :value.sync="project.name" />
        </Column>
        <Column :md="6">
          <Field
            label="Pavadinimas žemėlapyje"
            required
            :value.sync="project.nameShort" />
        </Column>
      </Row>
      <Row>
        <Column :md="3">
          <Field
            label="Projekto būsena"
            :options="projectStatusOptions"
            required
            type="select"
            :value.sync="project.status" />
          <Tag v-if="project.submittedAt" class="success">
            Projektas priduotas
          </Tag>
          <Btn
            v-else
            class="ib"
            :isLoading="isSubmitting"
            @click="submitProject">
            Priduoti projektą
          </Btn>
        </Column>
        <Column :md="3">
          <Field
            label="Proceso šaka"
            :options="projectStepBranchOptions"
            required
            type="select"
            :value.sync="project.stepBranch" />
        </Column>
        <Column :md="3">
          <Field
            label="Rezervacinis mokėjimas"
            :options="canSkipReservationOptions"
            required
            type="select"
            :value.sync="project.canSkipReservation" />
        </Column>
        <Column :md="3">
          <Field
            label="Slug (URL)"
            required
            :value.sync="project.slug" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Avansinis mokėjimas">
      <Row>
        <Column :md="3">
          <Field
            label="Avanso dydis, % (numatytasis)"
            required
            :step="0.1"
            type="number"
            :value.sync="project.advancePaymentDefaultPercent" />
        </Column>
        <Column :md="3">
          <Field
            label="Klientas gali siūlyti"
            :options="isAdvancePaymentCustomizableOptions"
            required
            type="select"
            :value.sync="project.isAdvancePaymentCustomizable" />
        </Column>
        <Column v-if="project.isAdvancePaymentCustomizable" :md="3">
          <Field
            label="Avanso dydis, % (min)"
            required
            :step="1"
            type="number"
            :value.sync="project.advancePaymentPercentMin" />
        </Column>
        <Column v-if="project.isAdvancePaymentCustomizable" :md="3">
          <Field
            label="Avanso dydis, % (max)"
            required
            :step="1"
            type="number"
            :value.sync="project.advancePaymentPercentMax" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Moduliai">
      <EquipmentSelect
        v-if="solarModules.length"
        fieldLabel="Moduliai"
        label="model"
        :multiple="true"
        :options="solarModules"
        placeholder="Ieškoti..."
        :value.sync="project.solarModules" />
    </FormSect>
    <FormSect title="Inverteriai">
      <EquipmentSelect
        v-if="solarInverters.length"
        fieldLabel="Inverteriai"
        label="model"
        :multiple="true"
        :options="solarInverters"
        placeholder="Ieškoti..."
        :value.sync="project.solarInverters" />
    </FormSect>
    <FormSect title="Konstrukcija">
      <Field
        label="Gamintojas"
        :value.sync="project.mountingManufacturer" />
      <Field
        label="Garantija (metai)"
        :value.sync="project.mountingWarranty" />
    </FormSect>
    <FormSect title="Viešas sutarties šablonas">
      <Field
        label="PDF nuoroda"
        :value.sync="project.contractPreviewUrl" />
    </FormSect>
    <FormSect title="Elektrinės informacija">
      <Row>
        <Column :md="6">
          <PinMap
            :position="[project.posX, project.posY]" />
        </Column>
        <Column :md="6">
          <Field
            label="Vietovė"
            type="text"
            :value.sync="project.location" />
          <Field
            label="Vietovė (sutrumpinta)"
            type="text"
            :value.sync="project.locationShort" />
          <Field
            label="Vietovė (sutartyje)"
            type="text"
            :value.sync="project.locationFull" />
          <Field
            label="Sklypo unikalus numeris"
            type="text"
            :value.sync="project.plotNumber" />
          <Field
            label="Sklypo plotas (ha)"
            :step="0.01"
            type="number"
            :value.sync="project.area" />
          <Field
            label="Parko atvaizdas (nuoroda)"
            type="text"
            :value.sync="project.imageUrl" />
          <Field
            label="Parko lokacija žemėlapyje (nuoroda į atvaizdą)"
            type="text"
            :value.sync="project.locationImageUrl" />
          <Field
            label="Parko schema (nuoroda į atvaizdą)"
            type="text"
            :value.sync="project.placementImageUrl" />
          <Row>
            <Column :md="6">
              <Field
                label="Pozicija X"
                :max="100"
                :min="0"
                type="number"
                :value.sync="project.posX" />
            </Column>
            <Column :md="6">
              <Field
                label="Pozicija Y"
                :max="100"
                :min="0"
                type="number"
                :value.sync="project.posY" />
            </Column>
          </Row>
        </Column>
      </Row>
      <!--
      <Column :md="4">
        <Field
          label="Rezervacijos terminas, dienos"
          required
          :step="1"
          type="number"
          :value.sync="project.termDays" />
      </Column>
      -->
      <Row>
        <Column :md="4">
          <Field
            label="Pirkimo kaina su PVM, € (sutartyje)"
            required
            :step="0.01"
            type="number"
            :value.sync="project.price" />
        </Column>
        <Column :md="4">
          <Field
            label="Metinė priežiūros kaina (su PVM), € (sutartyje)"
            required
            :step="0.01"
            type="number"
            :value.sync="project.maintenanceAnnual" />
        </Column>
        <Column :md="4">
          <DatePicker
            label="Pastatymo data (planuojama)"
            required
            :value.sync="project.constructionDate" />
        </Column>
      </Row>
      <Row>
        <Column :md="4">
          <Field
            label="Nuolaida (buvusi kaina)"
            :step="0.01"
            type="number"
            :value.sync="project.priceBefore" />
        </Column>
        <Column class="removeValueBtnContainer" :md="4">
          <Btn
            :disabled="project.priceBefore === null"
            @click="project.priceBefore = null">
            Trinti
          </Btn>
        </Column>
      </Row>
      <Row>
        <Column :md="4">
          <Field
            label="Nuolaida (buvusi priežiūros kaina)"
            :step="0.01"
            type="number"
            :value.sync="project.maintenanceAnnualBefore" />
        </Column>
        <Column class="removeValueBtnContainer" :md="4">
          <Btn
            :disabled="project.maintenanceAnnualBefore === null"
            @click="project.maintenanceAnnualBefore = null">
            Trinti
          </Btn>
        </Column>
      </Row>
      <label>Aprašymas</label>
      <VMdEditor v-model="project.description" />

      <!-- <Field
        label="Aprašymas"
        type="textarea"
        :value.sync="project.description" /> -->
    </FormSect>
    <FormSect class="capacity" title="Pajėgumas">
      <BarCapacity :project="project" />
      <Row>
        <Column :md="3">
          <Field
            disabled
            label="Užsakymais rezervuota, kW"
            type="number"
            :value.sync="project.capacityReserved" />
        </Column>
        <Column :md="3">
          <Field
            label="Neužrezervuojamas pajėgumas, kW"
            :min="0"
            required
            :step="0.1"
            type="number"
            :value.sync="project.capacityUnreservable" />
        </Column>
        <Column :md="3">
          <Field
            disabled
            label="Viešai matoma rezervuota, kW"
            type="number"
            :value="capacityUsed" />
        </Column>
        <Column :md="3">
          <Field
            label="Pilnas pajėgumas, kW (sutartyje)"
            :min="project.capacityUsed"
            required
            :step="0.1"
            type="number"
            :value.sync="project.capacity" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Skaičiuotuvas">
      <Row>
        <Column :md="6">
          <Field
            label="Mėnesinis mokestis, €/kWp"
            required
            step="any"
            type="number"
            :value.sync="project.feeMonthly" />
        </Column>
        <Column :md="6">
          <Field
            label="Metinė priežiūros kaina (be PVM), €"
            required
            step="any"
            type="number"
            :value.sync="project.omAnnual" />
        </Column>
      </Row>
      <Row>
        <Column :md="6">
          <Field
            label="Metinis produkcijos našumas, kWh/kWp"
            required
            step="any"
            type="number"
            :value.sync="project.yieldAnnual" />
        </Column>
        <Column :md="6">
          <Field
            label="EU parama, €/kWp"
            required
            step="any"
            type="number"
            :value.sync="project.subsidy" />
        </Column>
      </Row>
      <Row>
        <Column :md="6" :xl="4">
          <Field
            label="Garantuotas veikimas, metai"
            required
            type="number"
            :value.sync="project.lifetimeYears" />
        </Column>
        <Column :md="6" :xl="4">
          <Field
            label="Elektros kaina, €/kWh"
            required
            :step="0.0001"
            type="number"
            :value.sync="project.priceKwh" />
        </Column>
        <Column :md="6" :xl="4">
          <Field
            label="PVM dydis, %"
            required
            :step="0.1"
            type="number"
            :value="project.vatRate * 100"
            @update:value="project.vatRate = Number(($event / 100).toFixed(2))" />
        </Column>
      </Row>
    </FormSect>
    <ObjectOwnerForm
      :objectOwnerDetails.sync="project.objectOwnerDetails" />
    <PaymentAccountSelect
      :canChange="project.status === 'PLANNING'"
      :paymentAccountId.sync="project.paymentAccountId" />
    <FormSect title="Sutartis">
      <Row>
        <Column :md="4">
          <DatePicker
            label="Įrenginio perdavimo Pirkėjui terminas"
            :required="isActive"
            :value.sync="project.termDate" />
        </Column>
        <Column :md="4">
          <Field
            label="Įrenginio, kuris generuoja galią, gamintojas"
            required
            type="text"
            :value.sync="project.generatorManufacturer" />
        </Column>
        <Column :md="4">
          <Field
            label="Įrenginio, kuris generuoja galią, garantinis laikotarpis"
            required
            :step="1"
            type="number"
            :value.sync="project.generatorGuaranteeYears" />
        </Column>
      </Row>
      <Row>
        <Column :md="4">
          <Field
            label="Keitiklių bendra galia (leistinoji), kW"
            required
            :step="0.01"
            type="number"
            :value.sync="project.inverterKw" />
        </Column>
        <Column :md="4">
          <Field
            label="Įrenginio, kuris priskiriamas generuojamai galiai, gamintojas"
            required
            type="text"
            :value.sync="project.inverterManufacturer" />
        </Column>
        <Column :md="4">
          <Field
            label="Įrenginio, kuris priskiriamas generuojamai galiai, garantinis laikotarpis"
            required
            :step="1"
            type="number"
            :value.sync="project.inverterGuaranteeYears" />
        </Column>
      </Row>
      <Row>
        <Column :md="4">
          <Field
            label="Vid. metinis saulės apšvitos intensyvumas, kWh/m2/metus"
            :required="isActive"
            :step="0.1"
            type="number"
            :value.sync="project.solarRadiation" />
        </Column>
        <Column :md="6">
          <Field
            label="Metinis produkcijos našumas, kWh/kWp"
            :required="isActive"
            :step="0.01"
            type="number"
            :value.sync="project.powerGuaranteeKwh" />
        </Column>
        <Column :md="6">
          <Field
            label="Numatytas pogarantinis energijos kiekio mažėjimas, %"
            :required="isActive"
            :step="0.01"
            type="number"
            :value.sync="project.powerDecreasePercent" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Sąskaitos">
      <Row>
        <Column :md="6">
          <Field
            label="Sąskaitos numerio pradžia (prefix)"
            :maxlength="4"
            :minlength="4"
            :required="!project.id"
            type="text"
            :value.sync="project.invoicePrefix" />
        </Column>
        <Column :md="6">
          <Field
            label="Unikalaus užsakymo numerio pradžia Payseroje (prefix)"
            :maxlength="2"
            :minlength="2"
            :required="!project.id"
            type="text"
            :value.sync="project.payseraPrefix" />
        </Column>
      </Row>
    </FormSect>
    <FormSect title="ESO">
      <Row>
        <Column :md="6">
          <Field
            label="Jėgainės objekto numeris"
            :value.sync="project.objectNo" />
        </Column>
        <Column :md="6">
          <Field
            label="Jėgainės objekto savininkas"
            :value.sync="project.objectOwner" />
        </Column>
      </Row>
      <div class="documents">
        <h4>Parko dokumentai (juos gauna parko vartotojai, sumokėję likutinę užsakymo dalį)</h4>
        <Row>
          <Column
            v-for="projectDocument in projectDocuments"
            :key="projectDocument.id"
            :md="6">
            <ProjectDocument
              :canDelete="hasRole('>=MANAGER')"
              :projectDocument="projectDocument" />
          </Column>
        </Row>
        <FormProjectDocument
          v-for="(projectDocument, index) in documentForms"
          :key="index"
          :projectDocument="projectDocument"
          @remove="removeDocumentForm(index)"
          @upload="addDocumentForm" />
      </div>
    </FormSect>
    <FormSect class="formApi" title="API sinchronizacija">
      <Row>
        <Column :md="4">
          <Field
            label="Pasiekiami parkai"
            :options="plantsOptions"
            type="select"
            :value.sync="project.plantId" />
        </Column>
        <Column :md="8">
          <Row>
            <Column :md="8">
              <Field
                label="Breakpoints"
                :options="plantShareBreakpointLabels"
                type="select"
                :value.sync="plantDataShareBreakpointSelected" />
            </Column>
            <Column class="removeValueBtnContainer" :md="4">
              <Btn
                :disabled="!plantDataShareBreakpointSelected"
                @click="removePlantDataShareBreakpoint(plantDataShareBreakpointSelected)">
                Trinti
              </Btn>
            </Column>
          </Row>
          <Row>
            <Column :md="4">
              <Field
                label="Dalis (%)"
                :step="1"
                type="number"
                :value.sync="plantShareBreakpointNewRate" />
            </Column>
            <Column :md="4">
              <DatePicker
                :hasTime="true"
                label="Nuo"
                :value.sync="plantShareBreakpointNewStartAt" />
            </Column>
            <Column class="addBreakpointContainer" :md="4">
              <Btn
                :disabled="!plantShareBreakpointNewStartAt || !plantShareBreakpointNewRate"
                @click="addPlantDataShareBreakpoint">
                Pridėti
              </Btn>
            </Column>
          </Row>
        </Column>
      </Row>
    </FormSect>
    <FormSect title="Matomumas">
      <Field
        label="Viešas projektas"
        type="checkbox"
        :value.sync="project.isPublic" />
      <Field
        label="Matomas tituliniame"
        type="checkbox"
        :value.sync="project.isListed" />
      <Field
        label="Skaičiuoklei naudojamas projektas"
        type="checkbox"
        :value.sync="project.isMain" />
    </FormSect>
  </div>
</template>

<script>
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import { actions, getters } from 'views/utils'
import BarCapacity from 'components/admin/BarCapacity'
import DatePicker from 'components/fields/DatePicker'
import EquipmentSelect from './EquipmentSelect'
import FormProjectDocument from 'components/FormProjectDocument'
import Message from 'mixins/Message'
import ObjectOwnerForm from './ObjectOwnerForm'
import PaymentAccountSelect from './PaymentAccountSelect'
import PinMap from 'components/PinMap'
import ProjectDocument from 'components/ProjectDocument'
import VMdEditor from '@kangc/v-md-editor'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import hljs from 'highlight.js'

VMdEditor.use(githubTheme, {
  Hljs: hljs,
})
VMdEditor.lang.use('en-US', enUS)

export default {
  mixins: [Message],
  components: {
    BarCapacity,
    DatePicker,
    EquipmentSelect,
    FormProjectDocument,
    ObjectOwnerForm,
    PaymentAccountSelect,
    PinMap,
    ProjectDocument,
    VMdEditor,
  },
  props: {
    documentForms: Array,
    project: Object,
  },
  data() {
    return {
      canSkipReservationOptions: [
        {
          label: 'Privalomas',
          value: false,
        },
        {
          label: 'Klientas gali pasirinkti praleisti',
          value: true,
        },
      ],
      isAdvancePaymentCustomizableOptions: [
        {
          label: 'Ne (visada numatytasis)',
          value: false,
        },
        {
          label: 'Taip (tarp rėžių)',
          value: true,
        },
      ],
      plantDataShareBreakpointSelected: null,
      plantShareBreakpointNewStartAt: null,
      plantShareBreakpointNewRate: null,

      isSubmitting: false,
    }
  },
  computed: {
    isActive() {
      return this.project.status === 'ACTIVE'
    },
    capacityUsed() {
      return Number(
        (this.project.capacityReserved + (this.project.capacityUnreservable || 0)).toFixed(1),
      )
    },
    plantsOptions() {
      return [
        [
          null,
          'Nėra parko API',
        ],
        ...this
          .query('plant')
          .map(plant => [
            plant.id,
            `${plant.origin} - ${plant.name}`,
          ]),
      ]
    },
    solarInverters() {
      return this.query('solarInverter')
    },
    solarModules() {
      return this.query('solarModule')
    },
    projectDocuments() {
      return this.query('projectDocument', {
        projectId: this.project.id,
      })
    },
    plantShareBreakpointLabels() {
      if (!this.project.plantDataShareBreakpoints) return

      const plantDataShareBreakpoints = [
        ...this.project.plantDataShareBreakpoints,
      ].sort((a, b) => Date.parse(a.startAt) - Date.parse(b.startAt))

      return plantDataShareBreakpoints
        .map(breakpoint => [
          JSON.stringify(breakpoint),
          `Nuo ${new Date(breakpoint.startAt).toLocaleString('lt-LT')} naudojama ${breakpoint.rate}%`,
        ])
    },
    ...getters(
      'projectDocumentFactory',
      'projectStatusOptions',
      'projectStepBranchOptions',
    ),
  },
  methods: {
    addPlantDataShareBreakpoint() {
      this.project.plantDataShareBreakpoints = [
        ...this.project.plantDataShareBreakpoints || [],
        {
          startAt: this.plantShareBreakpointNewStartAt.toLocaleString('lt-LT'),
          rate: this.plantShareBreakpointNewRate,
        },
      ]

      this.plantShareBreakpointNewStartAt = null
      this.plantShareBreakpointNewRate = null
    },
    removePlantDataShareBreakpoint(breakpointStringified) {
      const breakpointToRemove = JSON.parse(breakpointStringified)
      this.project.plantDataShareBreakpoints = this.project.plantDataShareBreakpoints
        .filter(plantDataShareBreakpoint =>
          Date.parse(plantDataShareBreakpoint.startAt) !== Date.parse(breakpointToRemove.startAt) &&
          plantDataShareBreakpoint.rate !== breakpointToRemove.rate,
        )
    },
    addDocumentForm() {
      this.$emit('update:documentForms', [
        ...this.documentForms || [],
        this.projectDocumentFactory({
          projectId: this.project.id,
        }),
      ])
    },
    removeDocumentForm(indexRemove) {
      this.$emit('update:documentForms', this
        .documentForms
        .filter((_, index) => index !== indexRemove))
    },
    async submitProject() {
      this.PROMPT({
        confirm: () => this.$wrap(
          async () => {
            const { submittedAt } = await this.PATCH_PROJECT({
              id: this.project.id,
              submittedAt: new Date(),
            })

            this.project.submittedAt = submittedAt
          },
          'isSubmitting',
        ),
        text: 'Ar tikrai norite priduoti projektą? Tai sukurs PVM sąskaitas visiems užsakymams su apmokėtu avansiniu mokesčiu.',
      })
    },
    ...actions(
      'FETCH_PLANT',
      'FETCH_SOLAR_INVERTER',
      'FETCH_SOLAR_MODULE',
      'PATCH_PROJECT',
      'PROMPT',
    ),
  },
  async created() {
    this.FETCH_SOLAR_MODULE()
    this.FETCH_SOLAR_INVERTER()
    this.addDocumentForm()
    this.FETCH_PLANT()
  },
}
</script>

<style lang="scss">
.FormProject {
  .FormSect.capacity {
    .BarCapacity {
      margin-bottom: 2rem;
    }
  }

  .documents {
    margin-top: 2rem;

    h4 {
      margin-bottom: 1rem;
    }

    > .add {
      text-align: right;
    }
  }

  .formApi {
    .addBreakpointContainer {
      align-items: center;
      display: flex;
    }
  }

  .removeValueBtnContainer {
    align-items: flex-end;
    display: flex;
    margin-bottom: 1.2rem;
  }
}
</style>
